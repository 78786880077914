import axios from 'axios';
import {jwtDecode} from 'jwt-decode';

class UserClass {
  constructor() {
    const isLoggedIn = localStorage.getItem('isLoggedIn');
    const login = localStorage.getItem('login');
    const id = localStorage.getItem('id');
    this.login = login ?? false;
    this.id = id ?? false;
    this.state = {
      isLoggedIn: isLoggedIn ? JSON.parse(isLoggedIn) : false
    };
  }

  async logIn(login, password) {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
        login,
        password
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      const data = response.data;
      const authToken = data.authToken;
      localStorage.setItem('authToken', authToken); // Store token in local storage
      const decodedToken = jwtDecode(authToken); // Decode the token to get payload
      this.login = decodedToken.id;
      this.id = decodedToken.login;
      localStorage.setItem('id', decodedToken.id); // Optionally store user information
      localStorage.setItem('login', decodedToken.login); // Optionally store user information
      this.state.isLoggedIn = true;
      localStorage.setItem('isLoggedIn', JSON.stringify(true));
      return { result: true, message: "OK" };
    } catch (err) {
      return { result: false, message: `Error during login:, ${err}` };
    }
  }

  getAuthToken() {
    return localStorage.getItem('authToken');
  }

  async makeRequest(url, options = {}) {
    const authToken = this.getAuthToken();
    if (!authToken) {
      throw new Error('No authentication token found');
    }

    const config = {
      ...options,
      headers: {
        ...options.headers,
        'Authorization': `Bearer ${authToken}`,
      }
    };

    try {
      const response = await axios(url, config);
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle unauthorized access, e.g., redirect to login page
        localStorage.removeItem('authToken');
        window.location.href = '/login';
      }
      return error.message;
    }
  }

  logOut() {
    this.state.isLoggedIn = false;
    localStorage.removeItem('isLoggedIn');

    document.cookie.split(";").forEach(function(c) {
      document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });

    localStorage.clear();
    sessionStorage.clear();
    window.location.href = '/';
    return true;
  }
}

const user = new UserClass();
export default user;
