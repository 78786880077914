import React from 'react';
import user from '../../../../helpers/User';


const LogoutPage = () => {
    user.logOut();
    return (
       <></>
    );
};

export default LogoutPage;
