// c:\Lucru\Domains\artTracker\front\src\views\header.js

import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import {
  UserAddOutlined, AreaChartOutlined, FundOutlined, ClusterOutlined,
  MailOutlined, CloudOutlined, PictureOutlined, BuildOutlined, FileTextOutlined,
  CreditCardOutlined, StopOutlined, UnorderedListOutlined, PlayCircleOutlined,
  PauseCircleOutlined, LinkOutlined, SettingOutlined, LogoutOutlined, OpenAIOutlined,
  UserSwitchOutlined
} from '@ant-design/icons';
import user from '../helpers/User';
import '../Header.css'; // Custom CSS for the scrollbar

const { Sider } = Layout;
const userName = user?.login ?? 'Profile';

const menuItems = [
  { key: '/', label: 'Overview', path: '/', icon: <AreaChartOutlined /> },
  { key: '/stats', label: 'Stats', path: '/stats', icon: <FundOutlined /> },
  { key: '/ferma', label: 'Farm', path: '/ferma', icon: <BuildOutlined /> },
  { key: '/bundles', label: 'Bundles', path: '/bundles', icon: <LinkOutlined /> },
  { key: '/tasks', label: 'Tasks', path: '/tasks', icon: <OpenAIOutlined /> },
  {
    key: '/akks', label: 'Accounts', path: '/akks', icon: <UserAddOutlined />,
    children: [
      { key: '/akks/active', label: 'Active', path: '/akks/active', icon: <PlayCircleOutlined /> },
      { key: '/akks/waiting', label: 'Waiting', path: '/akks/waiting', icon: <PauseCircleOutlined /> },
      { key: '/akks/banned', label: 'Banned', path: '/akks/banned', icon: <StopOutlined /> },
      { key: '/akks/all', label: 'All', path: '/akks/all', icon: <UnorderedListOutlined /> }
    ]
  },
  { key: '/domains', label: 'Domains', path: '/domains', icon: <ClusterOutlined /> },
  { key: '/emails', label: 'Emails', path: '/emails', icon: <MailOutlined /> },
  { key: '/cloaking', label: 'Cloaking', path: '/cloaking', icon: <CloudOutlined /> },
  { key: '/creatives', label: 'Creatives', path: '/creatives', icon: <PictureOutlined /> },
  { key: '/texts', label: 'Texts', path: '/texts', icon: <FileTextOutlined /> },
  { key: '/cards', label: 'Cards', path: '/cards', icon: <CreditCardOutlined /> },
  {
    key: '/profile', label: userName, path: '/profile', icon: <UserSwitchOutlined />,
    children: [
      { key: '/user/settings', label: 'Settings', path: '/user/settings', icon: <SettingOutlined /> },
      { key: '/user/logout', label: 'Logout', path: '/user/logout', icon: <LogoutOutlined /> }
    ]
  }
];

// Transform menu items for antd Menu component
const transformMenuItems = (menuItems) => {
  return menuItems.map((item) => {
    if (item.children) {
      return {
        key: item.key,
        icon: item.icon,
        label: item.label,
        children: transformMenuItems(item.children)
      };
    }
    return {
      key: item.key,
      icon: item.icon,
      label: <Link to={item.path}>{item.label}</Link>
    };
  });
};

function Header({ user }) {
  const [collapsed, setCollapsed] = useState(() => {
    const savedState = localStorage.getItem('siderCollapsed');
    return savedState ? JSON.parse(savedState) : false;
  });

  const [openKeys, setOpenKeys] = useState(() => {
    const savedKeys = localStorage.getItem('openKeys');
    return savedKeys ? JSON.parse(savedKeys) : menuItems.map(item => item.key);
  });

  const location = useLocation();

  useEffect(() => {
    localStorage.setItem('siderCollapsed', JSON.stringify(collapsed));
  }, [collapsed]);

  useEffect(() => {
    localStorage.setItem('openKeys', JSON.stringify(openKeys));
  }, [openKeys]);

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
    
  };

  return (
    <Sider
      theme="dark"
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
      style={{ maxHeight: '100vh',minHeight: '100vh', overflowY: 'auto', position: "fixed", zIndex: 2 }} // Make Sider scrollable and set max height to screen height
      className="custom-scrollbar" // Apply custom scrollbar
    >
      <Menu
        theme="dark"
        selectedKeys={[location.pathname]}
        mode="inline"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        items={transformMenuItems(menuItems)}
      />
    </Sider>
  );
}

export default Header;
