import React from 'react';
import { Tabs, Form, Input, Radio, InputNumber, Button, Layout } from 'antd';

const { TabPane } = Tabs;
const { Header, Content } = Layout;

// Define form schemas for each tab
const formSchemas = {
  tab1: [
    { type: 'radio_group', name: 'option1', label: 'Option 1:', options: [
      { label: 'Option 1A', value: 'opt1a' },
      { label: 'Option 1B', value: 'opt1b' }
    ]},
    { type: 'input_text', name: 'comment1', label: 'Comment:' }
  ],
  tab2: [
    { type: 'input_number', name: 'number1', label: 'Number 1:', min: 0, max: 10 },
    { type: 'input_text', name: 'comment2', label: 'Comment:' }
  ]
};

const SettingsPage = () => {
  const renderForm = (schema) => (
    <Form layout="vertical">
      {schema.map(field => {
        switch (field.type) {
          case 'input_text':
            return (
              <Form.Item key={field.name} label={field.label} name={field.name}>
                <Input />
              </Form.Item>
            );
          case 'radio_group':
            return (
              <Form.Item key={field.name} label={field.label} name={field.name}>
                <Radio.Group>
                  {field.options.map(option => (
                    <Radio key={option.value} value={option.value}>
                      {option.label}
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
            );
          case 'input_number':
            return (
              <Form.Item key={field.name} label={field.label} name={field.name}>
                <InputNumber min={field.min} max={field.max} />
              </Form.Item>
            );
          default:
            return null;
        }
      })}
      <Form.Item>
        <Button type="primary" htmlType="submit">Submit</Button>
      </Form.Item>
    </Form>
  );

  return (
    <Layout>
      <Layout>
        <Content >
          <Tabs defaultActiveKey="1">
            <TabPane tab="Targeting" key="1">
              {renderForm(formSchemas.tab1)}
            </TabPane>
            <TabPane tab="Automation" key="2">
              {renderForm(formSchemas.tab2)}
            </TabPane>
            <TabPane tab="Texts" key="3">
              {renderForm(formSchemas.tab1)}
            </TabPane>
            <TabPane tab="Ads" key="4">
              {renderForm(formSchemas.tab2)}
            </TabPane>
            <TabPane tab="Auto-Ads" key="5">
              {renderForm(formSchemas.tab1)}
            </TabPane>
            <TabPane tab="Farm" key="6">
              {renderForm(formSchemas.tab2)}
            </TabPane>
            <TabPane tab="Cards" key="7">
              {renderForm(formSchemas.tab1)}
            </TabPane>
            <TabPane tab="Pixels" key="8">
              {renderForm(formSchemas.tab2)}
            </TabPane>
            <TabPane tab="ArtAnty" key="9">
              {renderForm(formSchemas.tab1)}
            </TabPane>
          </Tabs>
        </Content>
      </Layout>
    </Layout>
  );
};

export default SettingsPage;
