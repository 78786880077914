import React, { useState, useEffect } from 'react';
import { Tabs, Table, Upload, Button, message, Input, Dropdown, Menu, Spin, Modal, App } from 'antd';
import { UploadOutlined, LoadingOutlined, CopyOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';
import moment from 'moment';
import user from '../../../helpers/User';
import Tags from '../../globals/tags';

const { confirm } = Modal;

const Emails = () => {
  const { message } = App.useApp();
  const [currentUser] = useState(user.login);
  const [emailData, setEmailData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState({});

  const fetchEmails = async () => {
    setLoading(true);
    try {
      const response = await user.makeRequest(`${process.env.REACT_APP_API_URL}/emails/all`, {
        method: 'GET',
      });
      if (response.data.result) {
        const data = response.data.data;
        setEmailData(data);
      } else {
        message.error('Failed to fetch emails.');
      }
    } catch (err) {
      message.error(`Failed to fetch emails: ${err}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEmails();
  }, []);

  const handleDelete = async (email) => {
    let id = email.id;
    setActionLoading((prev) => ({ ...prev, [id]: true }));
    confirm({
      title: 'Do you want to delete this email?',
      content: `Email: ${email.email}`,
      onOk: async () => {
        try {
          const response = await user.makeRequest(`${process.env.REACT_APP_API_URL}/emails/delete/${id}`, {
            method: 'DELETE',
          });

          if (response.data.result) {
            setEmailData(emailData.filter(item => item.id !== id));
            message.success(`Email with id ${id} deleted.`);
          } else {
            message.error(`Failed to delete email with id ${id}: ${response.data.message}.`);
          }
        } catch (err) {
          message.error(`Failed to delete email with id ${id}: ${err}.`);
        } finally {
          setActionLoading((prev) => {
            const newActionLoading = { ...prev };
            delete newActionLoading[id];
            return newActionLoading;
          });
        }
      },
      onCancel() {
        setActionLoading((prev) => {
          const newActionLoading = { ...prev };
          delete newActionLoading[id];
          return newActionLoading;
        });
        message.info('Deletion canceled.');
      },
    });
  };

  const handleGetCode = async (email) => {
    let id = email.id;
    setActionLoading((prev) => ({ ...prev, [id]: true }));
    try {
      const response = await user.makeRequest(`${process.env.REACT_APP_API_URL}/emails/getCode`, {
        method: 'POST',
        data: {
          email: email.email,
          password: email.password,
          sender: 'security@facebookmail.com',
        },
      });
      if (response.data.result) {
        message.success(`Founded code: ${response.data.data}.`);
      } else {
        message.error(response.data.message);
      }
    } catch (err) {
      message.error(`${err}.`);
    } finally {
      setActionLoading((prev) => {
        const newActionLoading = { ...prev };
        delete newActionLoading[id];
        return newActionLoading;
      });
    }
  };

  const handleGetIfNoOwner = async (email) => {
    let id = email.id;
    setActionLoading((prev) => ({ ...prev, [id]: true }));
    confirm({
      title: 'Do you want to get this email?',
      content: `Email: ${email.email}`,
      onOk: async () => {
        try {
          const response = await user.makeRequest(`${process.env.REACT_APP_API_URL}/emails/get?id=${id}`, {
            method: 'GET',
          });

          if (response.data.result) {
            setEmailData(emailData.map(item => item.id === id ? { ...item, owner: user.login } : item));
            message.success(`Email ${email.email} is now owned by you.`);
          } else {
            message.error(`Failed to get email ${email.email}: ${response.data.message}.`);
          }
        } catch (err) {
          message.error(`Failed to get email ${email.email}: ${err}.`);
        } finally {
          setActionLoading((prev) => {
            const newActionLoading = { ...prev };
            delete newActionLoading[id];
            return newActionLoading;
          });
        }
      },
      onCancel() {
        setActionLoading((prev) => {
          const newActionLoading = { ...prev };
          delete newActionLoading[id];
          return newActionLoading;
        });
        message.info('Owning canceled.');
      },
    });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => message.success('Copied to clipboard'))
      .catch(err => message.error('Failed to copy'));
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
      render: (text) => (
        <Button icon={<CopyOutlined />} onClick={() => copyToClipboard(text)}>
          {text}
        </Button>
      ),
    },
    {
      title: 'Password',
      dataIndex: 'password',
      key: 'password',
      sorter: (a, b) => a.password.localeCompare(b.password),
      render: (text) => (
        <Button icon={<CopyOutlined />} onClick={() => copyToClipboard(text)}>
          {text}
        </Button>
      ),
    },
    {
      title: 'Date Created',
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      render: (timestamp) => moment(timestamp).format('HH:mm DD.MM.YYYY'),
      sorter: (a, b) => a.dateCreated - b.dateCreated,
    },
    {
      title: 'Owner',
      dataIndex: 'owner',
      key: 'owner',
      // sorter: (a, b) => a.owner.localeCompare(b.owner),
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      render: (_, record) => {
        return (
        <Tags record={record} records={emailData} setAccounts={setEmailData} table={"emails"} />
      )},
      sorter: (a, b) => a.tags.length - b.tags.length
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => {
        const menuItems = [
          {
            key: 'delete',
            label: (
              <span onClick={() => handleDelete(record)} disabled={actionLoading[record.id]}>
                {actionLoading[record.id] ? <LoadingOutlined spin /> : 'Delete'}
              </span>
            ),
          },
          {
            key: 'getCode',
            label: (
              <span onClick={() => handleGetCode(record)} disabled={actionLoading[record.id]}>
                {actionLoading[record.id] ? <LoadingOutlined spin /> : 'Get Last Code'}
              </span>
            ),
          },
          !record.owner && {
            key: 'get',
            label: (
              <span onClick={() => handleGetIfNoOwner(record)} disabled={actionLoading[record.id]}>
                {actionLoading[record.id] ? <LoadingOutlined spin /> : 'Get'}
              </span>
            ),
          },
        ].filter(Boolean); // Remove falsey values e.g., undefined

        return (
          <Dropdown menu={{ items: menuItems }} disabled={actionLoading[record.id]}>
            <Button disabled={actionLoading[record.id]}>
              {actionLoading[record.id] ? <LoadingOutlined spin /> : 'Actions'}
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const filteredAllEmails = emailData.filter((email) =>
    email.email.toLowerCase().includes(searchTerm.toLowerCase())
  ).map(email => ({...email, key: email.id}));

  const filteredMyEmails = emailData
    .filter((email) => email.owner === currentUser)
    .filter((email) =>
      email.email.toLowerCase().includes(searchTerm.toLowerCase())
    ).map(email => ({...email, key: email.id}));

  const allEmails = (
    <>
      <Input
        placeholder="Search emails"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ marginBottom: '20px' }}
      />
      <Spin spinning={loading}>
        <Table dataSource={filteredAllEmails} columns={columns} />
      </Spin>
    </>
  );

  const myEmails = (
    <>
      <Input
        placeholder="Search my emails"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{ marginBottom: '20px' }}
      />
      <Spin spinning={loading}>
          <Table dataSource={filteredMyEmails} columns={columns} />
        </Spin>
    </>
  );

  const importEmails = (
    <Upload
      name="file"
      customRequest={async ({ file, onSuccess, onError }) => {
        try {
          const formData = new FormData();
          formData.append('file', file);

          const response = await user.makeRequest(`${process.env.REACT_APP_API_URL}/emails/import`, {
            method: 'POST',
            data: formData,
          });

          if (response.data.result) {
            onSuccess();
            message.success(`${file.name} file uploaded successfully.`);
            await fetchEmails();
          } else {
            onError();
            message.error(response.data.message);
          }
        } catch (err) {
          onError();
          message.error(`${file.name} file upload failed.`);
        }
      }}
    >
      <Button icon={<UploadOutlined />}>Click to Upload</Button>
    </Upload>
  );

  return (
    <Tabs defaultActiveKey="1" style={{ width: '100%' }} items={[
      {
        key: '1',
        label: 'All Emails',
        children: allEmails,
      },
      {
        key: '2',
        label: 'My Emails',
        children: myEmails,
      },
      {
        key: '3',
        label: 'Import Emails',
        children: importEmails,
      },
    ]}>
    </Tabs>
  );
};

export default Emails;
