import React, { useState, useEffect } from 'react';
import { Alert, Space, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Main = () => {
  const [loading, setLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    // Simulate an asynchronous operation
    const timer = setTimeout(() => {
      setLoading(false);
      setShowAlert(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <>
    <HelmetProvider>
        <Helmet>
          <title>Main page - Welcome</title>
        </Helmet>
    </HelmetProvider>
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      {loading ? (
          <Space  size="large">
          <Spin indicator={antIcon} />
          <span style={{ fontSize: '1.5rem' }}>Initializing...</span>
        </Space>
      ) : showAlert ? (
        <Alert
          message="Page Under Development"
          description="This page is currently under development. Please check back later for updates."
          type="info"
          showIcon
          style={{
            maxWidth: '500px',
            background: 'rgba(255, 255, 255, 0.1)',
            borderColor: 'rgba(255, 255, 255, 0.3)',
            color: '#fff',
          }}
          icon={<LoadingOutlined spin style={{ color: '#fff' }} />}
        />
      ) : null}
    </div>
    </>
  );
};

export default Main;