import React, { useEffect, useState } from 'react';
import { Table, Button, Spin, Typography, Tabs, App } from 'antd';
import { SyncOutlined, DeleteOutlined, RedoOutlined, CloseOutlined, CopyOutlined } from '@ant-design/icons';
import DOMPurify from 'dompurify';
import user from '../../../helpers/User';
import Anty from '../../globals/anty';
import { useLocation } from 'react-router-dom';


let tasksCache = null;
let fetchTasksPromise = null;

// Singleton pattern for WebSocket instance
class WebSocketService {
  constructor() {
    this.socket = null;
  }

  initializeSocket() {
    if (!this.socket || this.socket.readyState >= WebSocket.CLOSING) {
      const socketUrl = `${process.env.REACT_APP_WSS_URL}/tasks/listen`;
      this.socket = new WebSocket(socketUrl, user.getAuthToken());

      this.socket.onopen = () => {
        // console.log("WebSocket is connected");
      };

      this.socket.onerror = (error) => {
        console.error('WebSocket Error:', error);
        this.socket = null;
      };

      this.socket.onclose = () => {
        // console.log("WebSocket is closed");
        this.socket = null;
      };
    }
  }

  getSocket() {
    this.initializeSocket();
    return this.socket;
  }

  closeSocket() {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.close();
      this.socket = null;
    }
  }
}



const websocketService = new WebSocketService();





const TasksPage = () => {
  const { message } = App.useApp();
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [tasksData, setTasksData] = useState([]);


  const fetchTasks = async () => {
  
    fetchTasksPromise = new Promise(async (resolve, reject) => {
      try {
        const response = await user.makeRequest(`${process.env.REACT_APP_API_URL}/tasks/get`, { method: 'get' });
        tasksCache = response.data.data;
        let tmptasksData = {};
        for(let key in tasksCache){
          let taskFunc = tasksCache[key].value
          tmptasksData[taskFunc] = tasksCache[key];
        }
        resolve(tmptasksData);
      } catch (error) {
        message.error('Failed to fetch tasks');
        reject(error);
      } finally {
        fetchTasksPromise = null;
      }
    });
  
    return fetchTasksPromise;
  };


  useEffect(() => {
    const socket = websocketService.getSocket();

    setLoading(true);
    fetchTasks()
      .then(tasks => setTasksData(tasks))
      .catch(() => {}) // Error is already handled in fetchTasks
      .finally(() => setLoading(false));

    const handleMessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.result && data.data) {
          const tasks = data.data;
          setTasks(tasks);
        } else {
          message.error(`Error: ${data.message}`);
        }
      } catch (err) {
        console.error('Error parsing WebSocket message:', err);
        message.error('Error parsing WebSocket message.');
      }
    };

    socket.addEventListener('message', handleMessage);

    return () => {
      socket.removeEventListener('message', handleMessage);
      websocketService.closeSocket(); // Close the socket when the component unmounts
    };
  }, [location]);


  const handleTaskAction = async (task, isDelete = false, bulk = false) => {
      if(!bulk)setLoading(true)
    try {
      await user.makeRequest(`${process.env.REACT_APP_API_URL}/tasks/update`, {
        method: 'POST',
        data: {
          updateArtTask: 1,
          task_id: task.id,
          force: 1,
          status: 'New',
          del: isDelete ? true : false,
        },
      });

      if (isDelete) {
        // const updatedTasks = tasks.filter(t => t.id !== task.id);
        // setTasks(updatedTasks);
        if(!bulk)message.success('Task deleted');
      } else {
        // const updatedTasks = tasks.map(t => 
        //   t.id === task.id ? { ...t, status: 'New', restart: t.restart + 1 } : t
        // );
        // setTasks(updatedTasks);
        if(!bulk)message.success('Task updated');
      }
    } catch (error) {
      message.error('Failed to update task');
    }finally{
      if(!bulk)setLoading(false)
    }
  };

  const handleTasksAction = async (action) => {
    try {
      setLoading(true)
      let filteredTasks;
      if (action === 'restartErrors') {
        filteredTasks = tasks.filter(task => task.status !== 'New');
      } else {
        const isDelete = action === 'deleteErrors';
        filteredTasks = tasks.filter(task => isDelete ? task.status !== 'New' : task.status === 'New');
      }

      for (const task of filteredTasks) {
        await handleTaskAction(task, action !== 'restartErrors', true);
      }

      message.success("Done.")
      // fetchTasks();
    } catch (error) {
      message.error('Failed to perform action on tasks');
    }finally{
      setLoading(false)
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => message.success('Copied to clipboard'))
      .catch(err => message.error('Failed to copy'));
  };

  const columns = [
    {
      title: 'Browser',
      dataIndex: 'basId',
      key: 'basId',
      render: (text, task) => {
        // task.id = task.basId;
        return (
          <Anty account={task}></Anty>
      )},
    },
    { 
      title: 'Account', 
      dataIndex: 'basId', 
      key: 'basId', 
      sorter: (a, b) => a.basId - b.basId,
      render: (text) => (
        <Button icon={<CopyOutlined />} onClick={() => copyToClipboard(text)}>
          {text}
        </Button>
      ),
    },
    { 
      title: 'RK', 
      dataIndex: 'accId', 
      key: 'accId', 
      sorter: (a, b) => a.accId.localeCompare(b.accId),
      render: (text) => (
        <Button icon={<CopyOutlined />} onClick={() => copyToClipboard(text)}>
          {text}
        </Button>
      ),
    },
    { title: 'Task', dataIndex: 'func', key: 'func', 
      render: (text) => (
        <>
        {tasksData[text]?.name??text}
        </>
      ),
      sorter: (a, b) => a.func.localeCompare(b.func) },
    { 
      title: 'Status', 
      dataIndex: 'status', 
      key: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (status) => (
        <span 
          style={{ color: status !== 'New' ? 'red' : '' }} 
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(status) }}>
        </span>
      )    
    },
    {
      title: 'Added',
      dataIndex: 'added',
      key: 'added',
      render: (timestamp) => new Date(timestamp * 1000).toLocaleString(),
      sorter: (a, b) => a.added - b.added,
    },
    {
      title: 'Updated',
      dataIndex: 'updated',
      key: 'updated',
      render: (timestamp) => new Date(timestamp * 1000).toLocaleString(),
      sorter: (a, b) => a.updated - b.updated,
    },
    { title: 'Tries', dataIndex: 'restart', key: 'restart', sorter: (a, b) => a.restart - b.restart },
    { title: 'Owner', dataIndex: 'login', key: 'login' }, // Added Owner column
    {
      title: 'Restart',
      key: 'restartTask',
      render: (text, task) => <Button icon={<SyncOutlined />} onClick={() => handleTaskAction(task)}>Restart</Button>,
    },
    {
      title: 'Delete',
      key: 'deleteTask',
      render: (text, task) => <Button icon={<CloseOutlined />} onClick={() => handleTaskAction(task, true)}>Delete</Button>,
    },
  ];

  const userLogin = user.login;

  const tabs = [
    {
      key: '1',
      label: 'All Tasks',
      children: (
        <Spin spinning={loading}>
          <Table dataSource={tasks} columns={columns} rowKey="id" />
        </Spin>
      ),
    },
    {
      key: '2',
      label: 'My Tasks',
      children: (
        <Spin spinning={loading}>
          <Table dataSource={tasks.filter(task => task.login === userLogin)} columns={columns} rowKey="id" />
        </Spin>
      ),
    },
  ];

  return (
    <div style={{ width: '100%' }}>
      <div className="topButtons">
        <Button icon={<RedoOutlined />} onClick={() => handleTasksAction('restartErrors')}>Restart errors</Button>
        <Button icon={<DeleteOutlined />} onClick={() => handleTasksAction('deleteErrors')}>Delete errors</Button>
        <Button icon={<DeleteOutlined />} onClick={() => handleTasksAction('deleteNew')}>Delete new</Button>
      </div>
      <div className="landsList">
        <Tabs defaultActiveKey="1" items={tabs} />
      </div>
    </div>
  );
};

export default TasksPage;
