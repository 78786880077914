import React, { useEffect, useState } from 'react';
import { Drawer, Input, Form, Row, Col, Tooltip, Button, Divider, App, Modal, Tree } from 'antd';
import { CopyOutlined, SearchOutlined } from '@ant-design/icons';
import user from '../../helpers/User';

const InfoDrawer = ({ visible, onClose, data, allData, updateData }) => {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(data);
    }
  }, [visible, data, form]);

  const handleFinish = async (values) => {
    const changedFields = {};
    Object.keys(values).forEach(key => {
      if (values[key] !== data[key]) {
        changedFields[key] = values[key];
      }
    });

    if (Object.keys(changedFields).length > 0) {
      let tmpArray = { ...changedFields };
      changedFields.id = values.id; // Include the ID in the changed fields
      Modal.confirm({
        title: 'Confirm Submission',
        content: (
          <div>
            <p>You have changed the following fields:</p>
            <Divider style={{ margin: '0.5rem 0' }} />
            <p style={{ fontWeight: 500, textTransform: 'capitalize' }}>
              {Object.keys(tmpArray).join(', ')}
            </p>
            <Divider style={{ margin: '0.5rem 0' }} />
            <p>Do you want to continue?</p>
          </div>
        ),
        onOk: async () => {
          try {
            let r = await user.makeRequest(`${process.env.REACT_APP_API_URL}/accounts/update`, { method: 'post', data: changedFields });
            if (r.data.result) {
              message.success('Account updated');
              let updatedAkk = { ...data, ...changedFields };
              updateData(updatedAkk);
            } else {
              message.error(r);
            }
          } catch (err) {
            message.error(err);
          }
          onClose();
        }
      });
    } else {
      onClose();
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => message.success('Copied to clipboard'))
      .catch(err => message.error('Failed to copy'));
  };

  const renderFields = () => {
    const categorizedFields = {
      notes: [],
      inputs: [],
      tokens: []
    };

    Object.keys(data).forEach((key) => {
      if (['tags', 'folder', 'updated', 'fName', 'lName', 'folder_id', 'email_id', 'phone', 'history'].includes(key)) return;

      if (key === 'notes') {
        categorizedFields.notes.push(
          <Col span={24} key={key}>
            <Form.Item name={key} label={capitalizeLabel(key)}>
              <Input.TextArea rows={2} autoComplete="off" />
            </Form.Item>
          </Col>
        );
      } else if (key.match('token')) {
        categorizedFields.tokens.push(
          <Col span={12} key={key}>
            <Form.Item name={key} label={capitalizeLabel(key)}>
              <Input.TextArea rows={1} autoComplete="off" />
            </Form.Item>
          </Col>
        );
      } else if (['rk', 'created', 'owner'].includes(key)) {
        categorizedFields.inputs.push(
          <Col span={4} key={key}>
            <Form.Item name={key} label={capitalizeLabel(key)} style={{ width: "100%", minWidth: "100%" }}>
              <Input
                addonAfter={
                  <Tooltip title="Copy to clipboard">
                    <Button
                      type="text"
                      icon={<CopyOutlined />}
                      onClick={() => copyToClipboard(data[key])}
                      style={{ height: 30 }}
                    />
                  </Tooltip>
                }
                autoComplete="off"
                disabled
              />
            </Form.Item>
          </Col>
        );
      } else if (key === 'id') {
        categorizedFields.inputs.push(
          <Form.Item name="id" key="id">
            <Input type="hidden" />
          </Form.Item>
        );
      } else {
        categorizedFields.inputs.push(
          <Col span={12} key={key}>
            <Form.Item name={key} label={capitalizeLabel(key)}>
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
        );
      }
    });

    return (
      <>
        <Row gutter={12}>
          {categorizedFields.notes}
        </Row>
        <Divider />
        <Row gutter={12}>
          {categorizedFields.inputs}
        </Row>
        <Divider />
        <Row gutter={12}>
          {categorizedFields.tokens}
        </Row>
      </>
    );
  };

  const capitalizeLabel = (label) => label.charAt(0).toUpperCase() + label.slice(1);

  const renderHistoryTreeData = (history) => {
    const historyMap = {};

    history.forEach((change, index) => {
      const dateString = new Date(change.date).toLocaleString('en-GB', {
        hour: '2-digit',
        minute: '2-digit',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });

      if (!historyMap[dateString]) {
        historyMap[dateString] = [];
      }

      historyMap[dateString].push({
        title: (
          <span style={{ fontWeight: 500, textTransform: 'capitalize' }}>
            {change.field}: From
            <Button type="text" icon={<CopyOutlined />} onClick={() => copyToClipboard(change.from ?? '-')} style={{ padding: "0px 6px", margin: "auto 6px" }} >{change.from ?? '-'}</Button>
            To
            <Button type="text" icon={<CopyOutlined />} onClick={() => copyToClipboard(change.to ?? '-')}
              style={{ padding: "0px 6px", margin: "auto 6px" }} >{change.to ?? '-'}</Button>
          </span>
        ),
        key: `${dateString}-${change.field}-${index}`,
      });
    });

    return Object.keys(historyMap).map((dateString, index) => ({
      title: dateString,
      key: `date-${index}`,
      children: historyMap[dateString],
    }));
  };

  const handleExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
    setAutoExpandParent(false);
  };

  const flatTree = (tree) => {
    const flatList = [];
    const traverse = (nodes) => {
      nodes.forEach(node => {
        flatList.push({ title: node.title, key: node.key });
        if (node.children) {
          traverse(node.children);
        }
      });
    };
    traverse(tree);
    return flatList;
  };

  const flattenTreeTitle = (title) => {
    if (typeof title === 'string') {
      return title;
    } else if (React.isValidElement(title) && title.props.children) {
      return title.props.children.reduce((acc, child) => {
        if (typeof child === 'string') {
          acc += child;
        } else if (React.isValidElement(child) && child.props.children) {
          acc += flattenTreeTitle(child.props.children);
        }
        return acc;
      }, '');
    } else if (Array.isArray(title)) {
      return title.reduce((acc, child) => acc += flattenTreeTitle(child), '');
    }
    return '';
  };

  const onSearch = (e) => {
    const { value } = e.target;
    setSearchValue(value);

    if (!value) {
      setExpandedKeys([]);
      return;
    }

    const treeData = renderHistoryTreeData(JSON.parse(data.history));
    const dataList = flatTree(treeData);
    const expandedKeys = dataList
      .filter(item => {
        const titleText = flattenTreeTitle(item.title).toLowerCase();
        return titleText.includes(value.toLowerCase());
      })
      .map(item => item.key);

    setExpandedKeys(expandedKeys);
    setAutoExpandParent(true);
  };

  return (
    <Drawer
      title={`${data.name} [${data.id}]`}
      open={visible}
      onClose={onClose}
      placement={"right"} 
      width="30%"
      footer={
        <div style={{ textAlign: 'right' }}>
          <Button onClick={onClose} style={{ marginRight: 8 }}>Cancel</Button>
          <Button onClick={() => form.submit()} type="primary">Submit</Button>
        </div>
      }
    >
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        {renderFields()}
      </Form>
      {data.history && (
        <>
          <Divider />
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search History"
            onChange={onSearch}
            style={{ marginBottom: 8 }}
          />
          <Tree
            onExpand={handleExpand}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            treeData={renderHistoryTreeData(JSON.parse(data.history))}
          />
        </>
      )}
    </Drawer>
  );
};

export default InfoDrawer;
