import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { Table, Button, Spin, Modal, Input, InputNumber, App, Select, Tabs, Layout, Dropdown, Badge, Popover } from 'antd';
import { DownOutlined, UserAddOutlined, ReloadOutlined, UsergroupAddOutlined, FilterOutlined, InfoCircleOutlined, CopyOutlined, SearchOutlined, QuestionCircleOutlined, PlusOutlined, DeleteOutlined, PlayCircleOutlined, StopOutlined, ClockCircleOutlined, MobileOutlined, DesktopOutlined, FolderAddOutlined } from '@ant-design/icons';
import user from '../../../helpers/User';
import Tags from '../../globals/tags';
import Anty from '../../globals/anty';
import TaksLists from '../../globals/tasks';
import InfoModal from '../../globals/accData';
import ApiRow from '../../globals/apiRow';
const { Content } = Layout;
const { Option } = Select;

const Ferma = () => {
  const { message } = App.useApp();
  const [accounts, setAccounts] = useState([]);
  const [folders, setFolders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [bulkModalOpen, setBulkModalOpen] = useState(false);
  const [bulkCount, setBulkCount] = useState(1);
  const [searchFilters, setSearchFilters] = useState({});
  const [activeTab, setActiveTab] = useState("All");
  const [selectedRows, setSelectedRows] = useState([]);
  const [tasksNames, setTasksNames] = useState([]);
  const tasksListsRefs = useRef({});
  const apiRowRefs = useRef({});
  const antyRefs = useRef({});
  const apiButtons = { today: true, yesterday: false };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      message.success('Copied to clipboard');
    } catch {
      message.error('Failed to copy');
    }
  };

  useEffect(() => {
    fetchAccounts();
  }, []);

  const fetchFolders = useCallback(async () => {
    try {
      const response = await user.makeRequest(`${process.env.REACT_APP_API_URL}/accounts/getFolders`, { method: 'get', data: {} });
      if (response.data.result) {
        setFolders(response.data.data);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error('Failed to fetch folders');
    }
  }, [message]);

  const fetchAccounts = useCallback(async () => {
    setLoading(true);
    await fetchFolders();
    try {
      const response = await user.makeRequest(`${process.env.REACT_APP_API_URL}/accounts/get`, { method: 'post', data: {} });
      if (response.data.result) {
        setAccounts(response.data.data);
        fetchTasks().then(tasks => setTasksNames(tasks));
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error('Failed to fetch accounts');
    } finally {
      setLoading(false);
    }
  }, [fetchFolders, message]);

  const fetchTasks = async () => {
    try {
      const response = await user.makeRequest(`${process.env.REACT_APP_API_URL}/tasks/get`, { method: 'get' });
      return response.data.data;
    } catch (error) {
      message.error('Failed to fetch tasks');
      return [];
    }
  };

  const updateData = (data) => {
    setAccounts(prevAccounts =>
      prevAccounts.map(account => (account.id === data.id ? { ...account, ...data } : account))
    );
  };

  const handleRegister = async (bulk = false) => {
    if(!bulk)setLoading(true);
    try {
      const response = await user.makeRequest(`${process.env.REACT_APP_API_URL}/accounts/add`, { method: 'post', data: {} });
      if (response.data.result) {
        try {
          let r = await user.makeRequest(`${process.env.REACT_APP_API_URL}/tasks/create`, { 
            method: 'post', 
            data: { 
              type: 1, 
              func: 'register', 
              accId: '', 
              basId: response.data.data.id 
            } 
          });
          if (r.data.result) {
            if(!bulk)message.success('Task created successfully');
          } else {
            message.error(r.data.message);
          }
        } catch (error) {
          message.error(error);
        }
        if(!bulk)await fetchAccounts();
      } else {
        message.error(response.data.message);
      }
    } catch (err) {
      message.error('Failed to create account: ' + err);
    } finally {
      if(!bulk)setLoading(false);
    }
  };

  const handleBulkRegister = async () => {
    setBulkModalOpen(false);
    setLoading(true);
    try {
      for (let i = 0; i < bulkCount; i++) {
        await handleRegister(true);
      }
      message.success('Done');
      await fetchAccounts();
    } catch (err) {
      message.error('Failed to create accounts: ' + err);
    } finally {
      setLoading(false);
    }
  };

  const handleMenuClick = async key => {
    if (key.includes('task_')) {
      const selectedTask = key.split('task_')[1];
      for (const accountId of selectedRows) {
        const taskListRef = tasksListsRefs.current[accountId];
        if (taskListRef) {
          await taskListRef.handleTaskClick(tasksNames.find(task => task.value === selectedTask));
        }
      }
    } else if (key === 'Delete') {
      console.log('Deleting selected accounts');
    } else if (key === 'Get'){
      console.log('Triggering bulk action: ' + key);
    } else if (key.includes('api_')) {
      const apiAction = key.split('api_')[1];
      for (const accountId of selectedRows) {
        const apiRowRef = apiRowRefs.current[accountId];
        if (apiRowRef) {
          await apiRowRef.handleClick(apiAction);
        }
      }
    } else if (key === 'start_mobile') {
      for (const accountId of selectedRows) {
        const antyRef = antyRefs.current[accountId];
        if (antyRef) {
          await antyRef.handleStartMobile();
        }
      }
    } else if (key === 'start_desktop') {
      for (const accountId of selectedRows) {
        const antyRef = antyRefs.current[accountId];
        if (antyRef) {
          await antyRef.handleStartDesktop();
        }
      }
    }
  };

  const bulkMenuItems = tasksNames.map(task => ({
    label: task.name,
    key: `task_${task.value}`,
    icon: <PlayCircleOutlined />,
  }));

  let bulkApiItems = [];
  if(apiButtons.today) bulkApiItems.push({ key: 'api_today', label: 'Today', icon: <ReloadOutlined /> });
  if(apiButtons.yesterday) bulkApiItems.push({ key: 'api_yesterday', label: 'Yesterday', icon: <ClockCircleOutlined /> });

  const bulkMenu = {
    items: [
      {
        type: 'group',
        label: 'Anty Actions',
        children: [
          { key: 'start_desktop', label: 'Start Desktop', icon: <DesktopOutlined /> },
          { key: 'start_mobile', label: 'Start Mobile', icon: <MobileOutlined /> }
        ]
      },
      {
        type: 'group',
        label: 'API',
        children: bulkApiItems,
      },
      {
        type: 'group',
        label: 'Actions',
        children: ['Delete', 'Get'].map(action => ({
          key: action,
          label: `${action} Selected`,
          icon: action === 'Delete' ? <DeleteOutlined /> : <InfoCircleOutlined />,
        }))
      },
      {
        type: 'group',
        label: 'Tasks',
        children: bulkMenuItems,
      }
    ],
    onClick: ({ key }) => handleMenuClick(key),
  };

  const handleDropdownClick = (action, record) => {
    if (action === 'Data') {
      setSelectedAccount(record);
      setModalOpen(true);
    } else if (action === 'Delete') {
      message.success('Delete');
    } else if (action === 'Get') {
      message.success('Get');
    }
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchFilters(prev => ({ ...prev, [selectedKeys.column]: selectedKeys.value }));
  };

  const resetFilter = (dataIndex, confirm) => {
    setSearchFilters(prev => {
      const newFilters = { ...prev };
      delete newFilters[dataIndex];
      return newFilters;
    });
    confirm();
  };

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div style={{ padding: 8, position: 'relative' }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch({ column: dataIndex, value: selectedKeys[0] }, confirm)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch({ column: dataIndex, value: selectedKeys[0] }, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          className='resetFilterClassname'
          onClick={() => { setSelectedKeys([]); resetFilter(dataIndex, confirm); }}
          size="small"
          style={{ width: 90, marginLeft: 5 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: () => {
      const isFiltered = Boolean(searchFilters[dataIndex]);
      return (
        <div style={{ position: 'relative' }}>
          <FilterOutlined style={{ color: isFiltered ? '#1890ff' : undefined }} />
        </div>
      );
    },
    onFilter: (value, record) => record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    sorter: (a, b) => a[dataIndex] < b[dataIndex] ? -1 : 1
  });

  const columns = useMemo(() => [
    {
      title: 'Browser',
      dataIndex: 'Browser',
      key: 'id',
      render: (text, record) => <Anty ref={el => (antyRefs.current[record.id] = el)} account={record} />,
    },
    {
      title: 'Account',
      dataIndex: 'id',
      key: 'id',
      render: (text, record) => (
        <Button icon={<CopyOutlined />} onClick={() => copyToClipboard(text, message)}>
          {text}
        </Button>
      ),
      ...getColumnSearchProps('ID')
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
      render: text => (
        <Button icon={<CopyOutlined />} onClick={() => copyToClipboard(text, message)}>
          {text}
        </Button>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Folder',
      dataIndex: 'Folder',
      key: 'folder',
      ...getColumnSearchProps('folder'),
      render: (text, record) => (
        <Select
          style={{ width: 167 }}
          value={record.folder}
          onChange={async (key, value) => {
            try {
              const response = await user.makeRequest(`${process.env.REACT_APP_API_URL}/accounts/update`, { method: 'post', data: { folder: key, id: record.id } });
              if (response.data.result) {
                setAccounts(prevAccounts =>
                  prevAccounts.map(account =>
                    account.id === record.id ? { ...account, folder: value } : account
                  )
                );
                let leftFolders = accounts.filter(account => account.folder === record.folder).length - 1;
                if (leftFolders < 1) setActiveTab(value.children);
                await fetchAccounts();
                message.success('Done.');
              } else {
                message.error(response.data.message);
              }
            } catch (error) {
              message.error('Failed to update folder!');
            }
          }}
        >
          {folders.map(folder => (
            <Option key={folder.id} value={folder.id}>{folder.name}</Option>
          ))}
        </Select>
      ),
      sorter: (a, b) => a.folder.localeCompare(b.folder),
    },
    {
      title: 'API',
      dataIndex: 'api',
      key: 'api',
      render: (_, record) => (
        <ApiRow ref={el => (apiRowRefs.current[record.id] = el)} record={record} buttons={apiButtons} />
      ),
    },
    {
      title: 'RK',
      dataIndex: 'rk',
      key: 'rk',
      ...getColumnSearchProps('rk'),
      render: text => text ? (
        <Button icon={<CopyOutlined />} onClick={() => copyToClipboard(text, message)}>
          {text}
        </Button>
      ) : (
        <QuestionCircleOutlined />
      ),
      sorter: (a, b) => a.rk ? a.rk.localeCompare(b.rk) : 1,
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      ...getColumnSearchProps('tags'),
      render: (_, record) => (
        <Tags record={record} records={accounts} setAccounts={setAccounts} table="accounts" />
      ),
      sorter: (a, b) => (a.tags ? a.tags.length : 0) - (b.tags ? b.tags.length : 0),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Dropdown
          menu={{ 
            items: [
              { key: 'Data', label: (<><InfoCircleOutlined /> Data</>) },
              { key: 'Delete', label: (<><FolderAddOutlined /> Get</>) },
              { key: 'Get', label: (<span style={{color: "red"}}><DeleteOutlined /> Delete</span>) },
            ], 
            onClick: ({ key }) => handleDropdownClick(key, record) 
          }}
        >
          <Button>
            Actions <DownOutlined />
          </Button>
        </Dropdown>
      ),
    },
    {
      title: 'Tasks',
      dataIndex: 'Tasks',
      key: 'id',
      render: (text, record) => (
        <TaksLists ref={el => (tasksListsRefs.current[record.id] = el)} account={record} />
      ),
    },
  ], [accounts, folders, getColumnSearchProps, message]);

  const handleTabChange = key => {
    setActiveTab(key);
  };

  const filteredAccounts = useMemo(() => {
    if (activeTab === 'All') {
      return accounts;
    } else {
      return accounts.filter(account => account.folder === activeTab);
    }
  }, [accounts, activeTab]);

  const tabItems = useMemo(() => {
    const folderItems = folders.map(folder => {
      const filteredFolderAccounts = accounts.filter(account => account.folder_id === folder.id);
      return filteredFolderAccounts.length > 0 ? {label: `${folder.name} (${filteredFolderAccounts.length})`, key: folder.name} : null;
    }).filter(Boolean);

    return [{ label: `All (${accounts.length})`, key: 'All' }, ...folderItems];
  }, [accounts, folders]);

  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: selectedRowKeys => setSelectedRows(selectedRowKeys),
  };

  return (
    <div style={{ width: '100%' }}>
      <div className="topButtons">
        <Button icon={<UserAddOutlined />} onClick={handleRegister}>Register Account</Button>
        <Button icon={<UsergroupAddOutlined />} onClick={() => setBulkModalOpen(true)}>Bulk Register</Button>
        <Button icon={<ReloadOutlined />} onClick={fetchAccounts}>Refresh Table</Button>
        {selectedRows.length > 0 && (
          <Dropdown menu={bulkMenu}>
            <Button icon={<DownOutlined />}>Bulk Actions</Button>
          </Dropdown>
        )}
      </div>
      <Tabs activeKey={activeTab} onChange={handleTabChange} items={tabItems} />
      <Spin spinning={loading}>
        <Table
          dataSource={filteredAccounts}
          columns={columns}
          rowKey="id"
          rowSelection={rowSelection}
        />
      </Spin>
      <InfoModal
        visible={modalOpen}
        onClose={() => setModalOpen(false)}
        data={selectedAccount || {}}
        allData={accounts}
        updateData={updateData}
      />
      <Modal
        title="Bulk Register Accounts"
        open={bulkModalOpen}
        onOk={handleBulkRegister}
        onCancel={() => setBulkModalOpen(false)}
      >
        <InputNumber
          min={1}
          value={bulkCount}
          onChange={setBulkCount}
          style={{ width: '100%' }}
        />
      </Modal>
    </div>
  );
};

export default Ferma;
