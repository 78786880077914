// Tags.js
import React, { useState, useEffect } from 'react';
import { Dropdown, Menu, Tag, Input, Layout, App } from 'antd';
import { PlusOutlined, EyeOutlined, EyeInvisibleOutlined, CopyOutlined, DeleteOutlined } from '@ant-design/icons';
import user from '../../helpers/User'


const Tags = ({ record, records, setAccounts, table }) => {
  const { message } = App.useApp();
  const [newTag, setNewTag] = useState('');
  const [expandTags, setExpandTags] = useState(false);

  // Utility function to normalize tags
  const normalizeTags = (tags) => {
    if (!tags) return [];
    if (typeof tags === 'string') return tags.split(',').map(tag => tag.trim());
    return tags;
  };

  // Normalize record tags on component mount and when record changes
  useEffect(() => {
    record.tags = normalizeTags(record.tags);
  }, [record]);

  const handleMenuClick = async (key, tag, record) => {
    if (key === 'copy') {
      navigator.clipboard.writeText(tag);
      message.success('Tag copied');
    } else if (key === 'delete') {
      const delData = {
        table: table,
        id: record.id,
        tags: [tag]
      };
      await user.makeRequest(`${process.env.REACT_APP_API_URL}/tags/del`, {
        method: 'post',
        data: delData
      });
      const updatedAccounts = records.map(account =>
        account.id === record.id
          ? { ...account, tags: normalizeTags(account.tags).filter(t => t !== tag) }
          : account
      );
      setAccounts(updatedAccounts);
      message.success('Tag deleted');
    }
  };

  const addTag = async (record, newTag) => {
    if (newTag && !normalizeTags(record.tags).includes(newTag)) {
      const setData = {
        table: table,
        id: record.id,
        tags: [newTag]
      };
      await user.makeRequest(`${process.env.REACT_APP_API_URL}/tags/set`, {
        method: 'post',
        data: setData
      });
      const updatedAccounts = records.map(account =>
        account.id === record.id
          ? { ...account, tags: [...normalizeTags(account.tags), newTag] }
          : account
      );
      setAccounts(updatedAccounts);
      message.success('Tag added');
      setNewTag('');
    }
  };

  const tagMenu = (tag) => ({
    items: [
      { key: "copy", label: (
        <>
          <CopyOutlined /> Copy
        </>
      ) },
      { key: "delete", label: (
        <span style={{color: "red"}}>
          <DeleteOutlined /> Delete
        </span>
      ) }
    ],
    onClick: ({ key }) => handleMenuClick(key, tag, record)
  });

  const renderedTags = expandTags ? normalizeTags(record.tags) : normalizeTags(record.tags).slice(-10);

  return (
    <>
      <div style={{ maxWidth: '450px', display: 'flex', flexWrap: 'wrap', gridRowGap: "6px" }}>
        {normalizeTags(record.tags).length > 10 && (
          expandTags ?
          <EyeInvisibleOutlined style={{ cursor: 'pointer', marginRight: '6px' }} onClick={() => setExpandTags(!expandTags)} /> :
          <EyeOutlined style={{ cursor: 'pointer', marginRight: '6px' }} onClick={() => setExpandTags(!expandTags)} />
        )}
        {renderedTags.map((tag) => (
          <Dropdown key={tag} menu={tagMenu(tag)} trigger={['click']}>
            <Tag color="blue" style={{ cursor: 'pointer' }}>
              {tag}
            </Tag>
          </Dropdown>
        ))}
        <Input
          size="small"
          style={{ width: '100px' }}
          placeholder="New Tag"
          value={newTag}
          onChange={(e) => setNewTag(e.target.value.trim())}
          onBlur={(e) => addTag(record, e.target.value.trim())}
          onPressEnter={() => addTag(record, newTag)}
          suffix={<PlusOutlined style={{cursor: "pointer"}} />}
        />
      </div>
    </>
  );
};

export default Tags;
