import React, { useState, useEffect, useMemo, forwardRef, useImperativeHandle } from 'react';
import { Button, Dropdown, Popover, Spin, Badge, App } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import user from '../../helpers/User';

let tasksCache = null;
let fetchTasksPromise = null;

// Singleton pattern for WebSocket instance
class WebSocketService {
  constructor() {
    this.socket = null;
  }

  initializeSocket() {
    if (!this.socket || this.socket.readyState >= WebSocket.CLOSING) {
      const socketUrl = `${process.env.REACT_APP_WSS_URL}/tasks/listen`;
      this.socket = new WebSocket(socketUrl, user.getAuthToken());

      this.socket.onopen = () => {
        // console.log("WebSocket is connected");
      };

      this.socket.onerror = (error) => {
        console.error('WebSocket Error:', error);
        this.socket = null;
      };

      this.socket.onclose = () => {
        // console.log("WebSocket is closed");
        this.socket = null;
      };
    }
  }

  getSocket() {
    this.initializeSocket();
    return this.socket;
  }

  closeSocket() {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.close();
      this.socket = null;
    }
  }
}

const websocketService = new WebSocketService();

const TaksLists = forwardRef(({ account }, ref) => {
  const [tasksNames, setTasksNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [taskStates, setTaskStates] = useState({});
  const location = useLocation();

  const { message } = App.useApp();

  const fetchTasks = async () => {
    if (tasksCache) return tasksCache;
    if (fetchTasksPromise) return fetchTasksPromise;
  
    fetchTasksPromise = new Promise(async (resolve, reject) => {
      try {
        const response = await user.makeRequest(`${process.env.REACT_APP_API_URL}/tasks/get`, { method: 'get' });
        tasksCache = response.data.data;
        resolve(tasksCache);
      } catch (error) {
        message.error('Failed to fetch tasks');
        reject(error);
      } finally {
        fetchTasksPromise = null;
      }
    });
  
    return fetchTasksPromise;
  };

  useEffect(() => {
    const socket = websocketService.getSocket();

    setLoading(true);
    fetchTasks()
      .then(tasks => setTasksNames(tasks))
      .catch(() => {}) // Error is already handled in fetchTasks
      .finally(() => setLoading(false));

    const handleMessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        if (data.result && data.data) {
          const tasks = data.data;

          const newTaskStates = {};
          tasks.forEach(task => {
            if (task.basId === account.id) {
              newTaskStates[task.func] = {
                status: task.status,
              };
            }
          });
          setTaskStates(newTaskStates);
        } else {
          message.error(`Error: ${data.message}`);
        }
      } catch (err) {
        console.error('Error parsing WebSocket message:', err);
        message.error('Error parsing WebSocket message.');
      }
    };

    socket.addEventListener('message', handleMessage);

    return () => {
      socket.removeEventListener('message', handleMessage);
      websocketService.closeSocket(); // Close the socket when the component unmounts
    };
  }, [account, location]);

  const handleTaskClick = async (task) => {
    try {
      let r = await user.makeRequest(`${process.env.REACT_APP_API_URL}/tasks/create`, { 
        method: 'post', 
        data: { 
          type: task.is_desktop, 
          func: task.value, 
          accId: account.rk, 
          priority: task.priority,
          basId: account.id
        } 
      });
      if(r.data.result)message.success('Task created successfully');
      else message.error(r.data.message);
    } catch (error) {
      message.error(error);
    }
  };

  // Count active and error tasks based on status
  const activeTaskCount = Object.values(taskStates).filter(task => task.status === 'New').length;
  const errorTaskCount = Object.values(taskStates).filter(task => task.status !== 'New').length;
  let errorOffset = -3;
  if(activeTaskCount > 0 && errorTaskCount > 0) errorOffset = 12;
  
  const items = useMemo(() => tasksNames.map(task => {
    const taskState = taskStates[task.value];
    const disabled = !!taskState;
    const danger = taskState && taskStates[task.value]?.status !== 'New';
    const content = taskState && (
      <div>
        <b>Status</b>: {taskState.status}
      </div>
    );

    return {
      key: task.id,
      disabled: disabled,
      danger: danger,
      label: content ? (
        <Popover content={content}>
          <span style={{ color: danger ? 'red' : 'inherit' }}>{task.name}</span>
        </Popover>
      ) : (
        task.name
      ),
      onClick: () => handleTaskClick(task) // Add onClick event
    };
  }), [tasksNames, taskStates]);

  // Expose a method to parent component to get non-disabled tasks
  useImperativeHandle(ref, () => ({
    getNonDisabledTasks: () => tasksNames.filter(task => !taskStates[task.value]),
    handleTaskClick: (task) => handleTaskClick(task)
  }));

  return (
    <Spin spinning={loading}>
      <Dropdown menu={{ items }}>
        <Badge color={"blue"} count={activeTaskCount} offset={[-3, 0]} size="small">
          <Badge count={errorTaskCount} offset={[errorOffset, 0]} size="small">
            <Button>
              Tasks <DownOutlined />
            </Button>
          </Badge>
        </Badge>
      </Dropdown>
    </Spin>
  );
});

export default TaksLists;
