import React, { useState } from 'react';
import { ConfigProvider, theme, App as AntApp } from 'antd';
import SignIn from './views/auth';
import './App.css';
import user from './helpers/User';
import StartTracker from './views/Router';

const { defaultAlgorithm, darkAlgorithm } = theme;



function App() {
  const [isLoggedIn] = useState(user.state.isLoggedIn);

  return (
    <ConfigProvider theme={{
      algorithm: defaultAlgorithm
    }}>
     <AntApp>
      {isLoggedIn ? <StartTracker user={user} /> : <SignIn user={user} />}
     </AntApp>
    </ConfigProvider>
  );
}

export default App;
