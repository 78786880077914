import React, { useRef, useState } from 'react';
import { Button, Space, Tooltip, App } from 'antd';
import { ReloadOutlined, ClockCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import user from '../../helpers/User';

const calculateTimeSinceUpdate = (date, now) => {
  if (!date) return 'Never';
  const updatedDate = new Date(date);
  const diffTime = Math.abs(now - updatedDate);
  const diffSeconds = Math.floor(diffTime / 1000);
  const diffMinutes = Math.floor(diffSeconds / 60);
  const diffHours = Math.floor(diffMinutes / 60);
  const diffDays = Math.floor(diffHours / 24);
  const diffMonths = Math.floor(diffDays / 30);
  const diffYears = Math.floor(diffMonths / 12);

  if (diffSeconds < 60) {
    return `${diffSeconds} seconds ago`;
  } else if (diffMinutes < 60) {
    return `${diffMinutes} minutes ago`;
  } else if (diffHours < 24) {
    return `${diffHours} hours ago`;
  } else if (diffDays < 30) {
    return `${diffDays} days ago`;
  } else if (diffMonths < 12) {
    return `${diffMonths} months ago`;
  } else {
    return `${diffYears} years ago`;
  }
};

const ApiRow = React.forwardRef(({ record, buttons = { today: false, yesterday: false }}, ref) => {
  const now = new Date();
  const { message } = App.useApp();
  const [loading, setLoading] = useState(false);

  const handleClick = async(label) => {
    setLoading(true)
    try {
      let r = await user.makeRequest(`${process.env.REACT_APP_API_URL}/tasks/create`, { 
        method: 'post', 
        data: { 
          type: 1, 
          func: 'refhreshCookieAndToken', 
          accId: record.rk, 
          priority: 3,
          basId: record.id
        } 
      });
      if(r.data.result)message.success('Task created successfully');
      else message.error(r.data.message);
    } catch (error) {
      message.error(error);
    }finally{
      setLoading(false)
    }
  };


  React.useImperativeHandle(ref, () => ({
    handleClick
  }));

  return (
    <div>
      <div>{calculateTimeSinceUpdate(record.UpdatedAt, now)}</div>
      <div>
        <Space>
          {buttons.today && (
            <Tooltip title="Today">
              {!loading?
              <Button icon={<ReloadOutlined />} onClick={() => handleClick('today')} />:
              <Button disabled={true} icon={<LoadingOutlined />} />
              }
            </Tooltip>
          )}
          {/* {buttons.yesterday && (
            <Tooltip title="Yesterday">
              <Button icon={<ClockCircleOutlined />} onClick={() => handleClick('yesterday')} />
            </Tooltip>
          )} */}
        </Space>
      </div>
    </div>
  );
});

export default ApiRow;
