import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { Button, App } from 'antd';
import { MobileOutlined, DesktopOutlined, LoadingOutlined } from '@ant-design/icons';
import user from '../../helpers/User';

const socketInstances = {};

const getSocketInstance = (url) => {
  if (!socketInstances[url] || socketInstances[url].readyState === WebSocket.CLOSED) {
    const socketInstance = new WebSocket(url, user.getAuthToken());

    socketInstance.onopen = () => {
      // WebSocket is connected
    };

    socketInstance.onerror = (error) => {
      console.error('WebSocket Error:', error);
      delete socketInstances[url];
    };

    socketInstance.onclose = () => {
      // WebSocket is closed
      delete socketInstances[url];
    };

    socketInstances[url] = socketInstance;
  }
  return socketInstances[url];
};

const closeSocketInstance = (url) => {
  if (socketInstances[url] && socketInstances[url].readyState === WebSocket.OPEN) {
    socketInstances[url].close();
    delete socketInstances[url];
  }
};

const AntyComponent = forwardRef(({ account }, ref) => {
  const { message } = App.useApp();
  const [isMobileActive, setIsMobileActive] = useState(false);
  const [isDesktopActive, setIsDesktopActive] = useState(false);
  const [proxyReady, setProxyReady] = useState(false);
  const [loadingPC, setLoadingPC] = useState(false);
  const [loadingMOB, setLoadingMOB] = useState(false);
  let keyName = 'basId';

  useEffect(() => {
    const socketUrl = `${process.env.REACT_APP_WSS_URL}/anty/listen`;
    const socket = getSocketInstance(socketUrl);
    const handleMessage = (event) => {
      try {
        const data = JSON.parse(event.data);

        if (data.result && data.data) {
          if(account.basId)keyName = 'basId';
          else keyName = 'id';
          const accountExists = data.data.some(item => item.account === account[keyName]);
          let isDesktop = data.data.some(item => item.account === account[keyName] && item.is_desktop);
          const isMobile = !isDesktop;
          const itemWithProxy = data.data.find(item => item.account === account[keyName] && item.proxy_id);

          setIsMobileActive(false);
          setIsDesktopActive(false);
          setProxyReady(false);
          if (isMobile) setIsMobileActive(accountExists);
          if (isDesktop) setIsDesktopActive(accountExists);
          if (itemWithProxy) setProxyReady(accountExists);
        } else {
          message.error(`Error: ${data.message}`);
        }
      } catch (err) {
        console.error('Error parsing WebSocket message:', err);
        message.error('Error parsing WebSocket message.');
      }
    };

    if (socket) {
      socket.addEventListener('message', handleMessage);
    }

    return () => {
      if (socket) {
        socket.removeEventListener('message', handleMessage);
        closeSocketInstance(socketUrl);
      }
    };
  }, [account]);

  const handleStopMobile = async () => {
    setLoadingMOB(true);
    if(account.basId) keyName = 'basId';
    else keyName = 'id';

    try {
      let r = await user.makeRequest(`${process.env.REACT_APP_ANTY_URL}/stop`, {
        method: "GET",
        params: {
          basId: account[keyName],
          accId: account.rk
        }
      });
      if (r.data) {
        message.success('Stop MOB account '+account[keyName]);
        setLoadingMOB(false);
        return true;
      } else {
        message.error(`Stop MOB request failed for account: `+account[keyName]);
        setLoadingMOB(false);
        return false;
      }
    } catch(err) {
      message.error(err.message);
      setLoadingMOB(false);
      return false;
    }
  };

  const handleStopDesktop = async () => {
    setLoadingPC(true);
    if (account.basId) keyName = 'basId';
    else keyName = 'id';

    try {
      let r = await user.makeRequest(`${process.env.REACT_APP_ANTY_URL}/stop`, {
        method: "GET",
        params: {
          basId: account[keyName],
          accId: account.rk,
          is_desktop: 1
        }
      });
      if (r.data) {
        message.success('Stop account '+account[keyName]);
        setLoadingPC(false);
        return true;
      } else {
        message.error(`Stop request failed for account: `+account[keyName]);
        setLoadingPC(false);
        return false;
      }
    } catch(err) {
      message.error(err.message);
      setLoadingPC(false);
      return false;
    }
  };

  const handleStartMobile = async () => {
    setLoadingMOB(true);
    if (account.basId) keyName = 'basId';
    else keyName = 'id';

    try {
      let r = await user.makeRequest(`${process.env.REACT_APP_ANTY_URL}/start`, {
        method: "GET",
        params: {
          basId: account[keyName],
          accId: account.rk
        }
      });
      if (r.data) {
        message.success('Starting MOB account ' + account[keyName]);
        setLoadingMOB(false);
        return true;
      } else {
        message.error(`Start MOB request failed for account: ` + account[keyName]);
        setLoadingMOB(false);
        return false;
      }
    } catch(err) {
      message.error(err.message);
      setLoadingMOB(false);
      return false;
    }
  };

  const handleStartDesktop = async () => {
    setLoadingPC(true);
    if (account.basId) keyName = 'basId';
    else keyName = 'id';

    try {
      let r = await user.makeRequest(`${process.env.REACT_APP_ANTY_URL}/start`, {
        method: "GET",
        params: {
          basId: account[keyName],
          accId: account.rk,
          type: 1
        }
      });
      if (r.data) {
        message.success('Starting account ' + account[keyName]);
        setLoadingPC(false);
        return true;
      } else {
        message.error(`Start request failed for account: ` + account[keyName]);
        setLoadingPC(false);
        return false;
      }
    } catch(err) {
      message.error(err.message);
      setLoadingPC(false);
      return false;
    }
  };

  useImperativeHandle(ref, () => ({
    handleStartMobile,
    handleStartDesktop,
  }));

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-start', gridGap: "6px" }}>
      <Button onClick={isDesktopActive ? handleStopDesktop : handleStartDesktop} style={{ padding: '6px' }} 
              type={isDesktopActive ? 'primary' : 'default'}>
        {loadingPC || (isDesktopActive && !proxyReady)?<LoadingOutlined /> : <DesktopOutlined />}
      </Button>
      <Button onClick={isMobileActive? handleStopMobile : handleStartMobile} style={{ padding: '6px' }} 
              type={isMobileActive ? 'primary' : 'default'}>
        {loadingMOB || (isMobileActive && !proxyReady)? <LoadingOutlined /> : <MobileOutlined />}
      </Button>
    </div>
  );
});

export default AntyComponent;
