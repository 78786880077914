import React, { useState } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, Layout, Typography, message } from 'antd';
import { motion } from 'framer-motion';
import user from '../helpers/User';

const { Content } = Layout;
const { Title } = Typography;

const SignIn = () => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    const authResult = await user.logIn(values.username, values.password);
    setLoading(false);
    if (!authResult.result) {
      message.error(authResult.message);
    } else {
      window.location.href = '/';
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '2rem',
        }}
      >
        <motion.div
          style={{ maxWidth: '400px', width: '100%' }}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <motion.div
            style={{ textAlign: 'center', marginBottom: '2rem' }}
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.3, delay: 0.2 }}
          >
            <LockOutlined style={{ fontSize: '3rem' }} />
            <Title style={{ fontSize: '1rem' }} level={3}>
              Sign In
            </Title>
          </motion.div>
          <Form
            name="signin"
            initialValues={{ remember: true }}
            onFinish={handleSubmit}
          >
            <motion.div
              initial={{ x: -50, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.3, delay: 0.4 }}
            >
              <Form.Item
                name="username"
                rules={[{ required: true, message: 'Please input your Username!' }]}
              >
                <Input
                  prefix={<UserOutlined />}
                  placeholder="Username"
                  size="large"
                  disabled={loading}
                />
              </Form.Item>
            </motion.div>
            <motion.div
              initial={{ x: 50, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.3, delay: 0.6 }}
            >
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Password"
                  size="large"
                  disabled={loading}
                />
              </Form.Item>
            </motion.div>
            <motion.div
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.3, delay: 0.8 }}
            >
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: '100%' }}
                  size="large"
                  loading={loading}
                  disabled={loading}
                >
                  Sign In
                </Button>
              </Form.Item>
            </motion.div>
          </Form>
        </motion.div>
      </Content>
    </Layout>
  );
};

export default SignIn;
