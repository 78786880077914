import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Result } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const NotFound = () => {
  const navigate = useNavigate();

  const handleBackToHome = () => {
    navigate('/');
  };

  return (
    <>
    <HelmetProvider>
        <Helmet>
          <title>404 - Sorry, the page you visited does not exist.</title>
        </Helmet>
    </HelmetProvider>
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary" onClick={handleBackToHome}>
          Back to Home
        </Button>
      }
    />
    </>
  );
};

export default NotFound;